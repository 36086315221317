import React, { useEffect, useState } from 'react'

import styles from './WidgetContainer.module.css'
import EventsWidget from './EventsWidget'
import { NewFetchError } from '../Fetch'
import { ImageServiceContextProvider } from '../ImageServiceContext'

const widgets = {
  events: EventsWidget,
}

export default ({ type, site, entrypoint = 'https://widgets.vyb.events/api/widgets', options }) => {
  const [data, setData] = useState()
  const [error, setError] = useState()
  useEffect(() => {
    ;(async () => {
      let cancelled = false
      try {
        if (!site) {
          throw new Error('you must supply a site')
        }
        if (!type) {
          throw new Error('you must supply a type')
        }
        const resp = await fetch(`${entrypoint}?site=${encodeURIComponent(site)}&type=${encodeURIComponent(type)}`, {})
        if (!resp.ok) {
          throw await NewFetchError(resp)
        }
        const data = await resp.json()
        if (!cancelled) {
          setData(data)
          setError(null)
        }
      } catch (e) {
        if (!cancelled) {
          setError(e)
        }
      }
      return () => {
        cancelled = true
      }
    })()
  }, [entrypoint, site, type])
  if (error) {
    throw error
  }
  const Component = widgets[type]
  if (!Component) {
    throw new Error(`no such widget: ${type}`)
  }
  return (
    <div className={styles.root}>
      {Component && data && (
        <ImageServiceContextProvider imagesUrl={`${data.imagesUrl}/api/images`}>
          <Component {...options} data={data} />
        </ImageServiceContextProvider>
      )}
    </div>
  )
}
