import { Component } from 'react'
import isEqual from 'lodash/isEqual'

export async function NewFetchError(resp) {
  let text
  try {
    text = await resp.text()
  } catch (e) {
    text = null
  }
  return new FetchError(resp, text)
}

export class FetchError extends Error {
  constructor(resp, respText) {
    super(`[${resp.status}] ${resp.statusText}`)
    this.resp = resp
    this.respText = respText
  }
}

export default class Fetch extends Component {
  static defaultProps = {
    input: undefined,
    init: undefined,
    dataType: 'json',
    throwError: true,
  }
  state = {
    data: null,
    isFetching: true,
    fetchId: 0,
    error: null,
  }
  _mounted = false

  componentDidMount() {
    this._mounted = true
    this.performFetch(this.props)
  }

  componentWillUnmount() {
    this._mounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.input, prevProps.input) ||
      !isEqual(this.props.init, prevProps.init) ||
      this.props.dataType !== prevProps.dataType
    ) {
      this.performFetch(this.props)
    }
  }

  async performFetch({ input, init, dataType }) {
    let fetchId
    this.setState((state, props) => {
      fetchId = state.fetchId + 1
      return { isFetching: true, fetchId, error: null }
    })
    let data
    try {
      const resp = await fetch(input, init)
      if (!this._mounted || fetchId !== this.state.fetchId) {
        return
      }
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      // TODO Consider exposing arrayBuffer, blob, and formData
      switch (dataType) {
        case 'json':
          data = await resp.json()
          break
        case 'text':
          data = await resp.text()
          break
        case 'none':
          data = null
          break
        default:
          throw new Error(`unsupported dataType: ${dataType}`)
      }
    } catch (error) {
      if (this._mounted && fetchId === this.state.fetchId) {
        this.setState({ isFetching: false, error })
        return
      }
    }
    if (this._mounted && fetchId === this.state.fetchId) {
      this.setState({ data, isFetching: false, error: null })
    }
  }

  render() {
    if (this.props.throwError && this.state.error !== null) {
      throw this.state.error
    }
    return this.props.children({ ...this.state })
  }
}
