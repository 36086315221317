import React from 'react'

import { ImageServiceContext } from './ImageServiceContext'

export default ({ checksum, region, size, rotation, quality, format, alt, ...props }) => {
  return (
    <ImageServiceContext.Consumer>
      {({ imageURL }) => {
        const src = imageURL({ checksum, region, size, rotation, quality, format })
        if (!src) {
          return null
        }
        return <img src={src} alt={alt} {...props} />
      }}
    </ImageServiceContext.Consumer>
  )
}
