import React from 'react'

import styles from './WidgetErrorBoundary.module.css'

export default class WidgetErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.toString() }
  }

  componentDidCatch(error, info) {
    console.log(`[Vyb Events] error in widget at ${this.props.selector}: ${error}`)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <h2>Widget Error</h2>
          <div>{this.props.selector}</div>
          <div>{this.state.message}</div>
        </div>
      )
    }
    return this.props.children
  }
}
