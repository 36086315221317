import React from 'react'
import moment from 'moment-timezone'

import styles from './EventsWidget.module.css'
import Image from '../Image'

const WidgetCard = ({ event, siteUrl, timezone }) => {
  const displayDate = moment(event.start).tz(timezone)
  return (
    <div className={`${styles.widgetCard} ${event.featured ? styles.featured : styles.backfilled}`}>
      <div className={styles.thumbnail}>
        <Image className={styles.image} checksum={event.imageChecksum} size={event.featured ? '350,' : '200,'} />
      </div>
      <div className={styles.meta}>
        <a href={`${siteUrl}/event/${encodeURIComponent(event.id)}`}>
          <h3>{event.title}</h3>
        </a>
        <div className={styles.date}>{displayDate.format('MMM Do')}</div>
      </div>
    </div>
  )
}

export default ({ data }) => {
  return (
    <div className={styles.root}>
      <a title="Events home" href={data.siteUrl}>
        <h2>Events</h2>
      </a>
      <form className={styles.search} method="get" action={`${data.siteUrl}/search`}>
        <input className={styles.input} name="q" type="text" placeholder="Search for events" />
        <input className={styles.submit} type="submit" value="Search" />
      </form>
      <div>
        {data.data.events.map((e, i) => (
          <WidgetCard key={i} event={e} siteUrl={data.siteUrl} timezone={data.timezone} />
        ))}
      </div>
      <a className={styles.callToAction} href={`${data.siteUrl}/create/event`}>
        Add Your Event
      </a>
      <div className={styles.branding}>powered by Vyb Events</div>
    </div>
  )
}
