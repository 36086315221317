import React from 'react'
import ReactDOM from 'react-dom'

import WidgetContainer from './WidgetContainer'
import WidgetErrorBoundary from './WidgetErrorBoundary'

const widget = ({ selector, ...props }) => {
  const target = document.querySelector(selector)
  if (!target) {
    console.log(`[Vyb Events] could not find target element for selector ${selector}`)
    return
  }
  ReactDOM.unmountComponentAtNode(target)
  ReactDOM.render(
    <WidgetErrorBoundary selector={selector}>
      <WidgetContainer {...props} />
    </WidgetErrorBoundary>,
    target
  )
}

export default { widget }
